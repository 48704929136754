body{
	background: rgb(228,104,20);
	background: linear-gradient(0deg, rgba(228,104,20,1) 0%, rgba(218,37,29,1) 100%);
	color: #fff;
}

nav .carousel {
	min-height: 22rem;
}

.btn.btn-outline-light:hover {
	color: rgb(228,104,20);
}